import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook9/image/imgPortadaEbook9.png"
import img1 from "@components/pageEbook/ebooks/ebook9/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook9/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook9/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook9/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook9/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook9/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook9/image/imgSilder4.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo crear efectivas campañas de telemarketing
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      Se calcula que el 70% de los vendedores todavía se conectan con prospectos
      y generan reuniones por teléfono.
      <br /> <br />
      Sin embargo, hacer una llamada exitosa es más fácil decirlo que hacerlo.
      Por eso, en este e-book aprenderás a tener un ciclo de ventas más
      corto y acelerar sus procesos de ventas.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    El telemarketing puede ser difícil y tomar largas horas para prepararse para
    las campañas, a veces con pocos resultados. Sin embargo, sigue siendo el
    método más efectivo para ponernos en contacto con los clientes potenciales.
    <br /> <br />
    Por lo tanto, es de vital importancia saber cómo preparar, configurar e
    implementar adecuadamente tu campaña de telemarketing.
  </p>
)
const textTwo = (
  <p>
    Saber crear un guion de telemarketing permite saber qué decir y en qué
    momento exponer una información.
    <br /> <br />
    Además, ayuda a mane al cliente involucrado en la conversación. Por eso,
    aprenderás paso a paso a cómo estructurar tu propio speech.{" "}
  </p>
)
const textThree = (
  <p>
    Los KPIs son clave para saber el rendimiento de tus campañas de
    telemarketing. Por eso, en esta sección conocerás 5 indicadores ideales para
    tus operaciones.
  </p>
)

const data = {
  start: {
    support: "Ebook | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-9",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "6 pasos para crear una campaña de telemarketing",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "¿Cómo crear un guion para telemarketing?",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "5 KPIs de telemarketing para medir el rendimiento",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Crear una campaña de telemarketing en 6 pasos.",
      },
      {
        key: 2,
        text: "Escribir un guion para telemarketing para mejorar tus resultados.",
      },
      {
        key: 3,
        text: "Reconocer cuáles son las 3 herramientas de telemarketing que debes tener.",
      },
      {
        key: 4,
        text: "Identificar los 5 KPIs de telemarketing para medir el rendimiento de tus campañas.",
      },
      {
        key: 5,
        text: "Tener un ciclo de ventas más corto y acelerar sus procesos de ventas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
