import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook9/dataPageEbook9"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>6 pasos para crear una exitosa campaña de telemarketing [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/campanas-efectivas-call-center/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te enseñamos cómo crear una exitosa campaña de telemarketing dentro de tu negocio, para que así puedas aumentar tus ventas y fidelizar tus clientes. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="6 pasos para crear una exitosa campaña de telemarketing"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/campanas-efectivas-call-center/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Ebook/og-imgEbook9.jpg`}
      />
      <meta
        property="og:description"
        content="Te enseñamos cómo crear una exitosa campaña de telemarketing dentro de tu negocio, para que así puedas aumentar tus ventas y fidelizar tus clientes. "
      />
    </Helmet>
    <TemplatePageEbook ebook="telemarketing" location={location} typeEbook="ebook9" clickHereHref="https://beexcc.com/blog/telemarketing/" data={data} />
  </div>
)

export default IndexPage
